import styled from 'styled-components';
import { palette } from 'styled-theme';

export const NavbarMobileContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${palette('primary', 0)};
  z-index: 99999;
  overflow: hidden;
  pointer-events: none;

  @media (min-width: 60em) {
    display: none;
  }
`

export const NavbarMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${palette('primary', 1)};
  color: hsla(0,0%,100%,.5);
  width: 90vw;
  height: 100vh;
  right: 0;
  top: 0;
  z-index: 99999;
  padding-top: 1rem;
  padding-bottom: 1rem;

  @media (min-width: 60em) {
    display: none;
  }

  .navigation__head {
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 80vw;
    pointer-events: auto;

    h2 {
      font-weight: 600;
      font-size: 1.85rem;
      line-height: 1.75rem;
      color: hsla(0,0%,100%,.5);
      padding: 0.5rem;
    }

    .navigation__closeButton {
      padding: 0.5rem;
    }
  }

  .menu__navigation {
    padding-left: 1rem;
    padding-right: 1rem;
    flex: 1 1 0%;
    margin-top: 1.5rem;
    width: 75vw;
    .navigation {
      overflow: hidden;
      .navigation__list {
        pointer-events: auto;
        margin: 0;
        padding: 0;
        border: 0;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        width: auto;
        list-style: none;
        line-height: inherit;
        height: inherit;
        .navigation__item {
          .navigation__link {
              text-align: left;
              color: inherit;
              text-decoration: none;
              display: block;
              position: relative;
              color: hsla(0,0%,100%,.7);
              font-weight: 700;
              font-size: 1.5rem;
              text-transform: uppercase;
              -webkit-transition: color .15s linear;
              transition: color .15s linear;
              width: auto;
              height: inherit;
              line-height: 2;
              padding-top: 0.5rem;
              padding-bottom: 0.5rem;

              &:focus {
                color: #fff;
                fill: hsla(0,0%,100%,.5);
            }
          }
      }
    }
  }
`;