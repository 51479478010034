import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getDescriptor } from '@craftercms/redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';

import HeaderHolder from './HeaderStyle';
import HeaderSearch from './HeaderSearch';
import { NavbarMobileContainer, NavbarMenu } from './NavBarStyle';
import './transition.css';

import { nou } from '../../utils';

class Header extends Component {
  constructor(props) {
    super(props);

    this.levelDescriptorUrl = '/site/website/crafter-level-descriptor.level.xml';

    if (nou(props.descriptors[this.levelDescriptorUrl])) {
      this.props.getDescriptor(this.levelDescriptorUrl);
    }

    this.state = {
      openNavigation: false
    };

    this.onShowNavigationMenu = this.onShowNavigationMenu.bind(this);
    this.onHideNavigationMenu = this.onHideNavigationMenu.bind(this);
  }

  renderNavItems() {
    var rootId = '/';

    return this.props.nav.childIds[rootId].map((id, i) => {
      var navItem = this.props.nav.entries[id];

      return (
        <li key={i} className="navigation__item">
          <Link className="navigation__link navigation__link--apps" to={navItem.url}>
            <span className="navigation__link--text">
              {navItem.label}
            </span>
          </Link>
        </li>
      );
    });
  }

  renderSearchLink() {
    return (
      <li key="search-link" className="navigation__item">
        <Link className="navigation__link navigation__link--apps" to="/search">
          <span className="navigation__link--text">
            Search
          </span>
        </Link>
      </li>
    );
  }

  renderHeaderLogo(descriptor) {
    const {siteLogo, logoLink_s, logoLinkopeninnewtab_b} = descriptor.component;

    return (
      <a
        className="header__logo active"
        href={logoLink_s}
        target={logoLinkopeninnewtab_b === 'true' ? '_blank' : '_self'}
        style={{ backgroundImage: `url(${siteLogo})` }}
      >
        Video Center
      </a>
    );
  }

  renderHeaderHomeLink(descriptor) {
    const {homeText_s, homeLink_s} = descriptor.component;

    return (
      <li className="navigation__item">
        <Link className="navigation__link navigation__link--apps" to={homeLink_s}>
          <span className="navigation__link--text">
            {homeText_s}
          </span>
        </Link>
      </li>
    )
  }

  onShowNavigationMenu() {
    const appElm = this.props.appRef.current;
    appElm.style['overflow-y'] = 'hidden';
    this.setState({ openNavigation: true });
  }

  onHideNavigationMenu() {
    const appElm = this.props.appRef.current;
    appElm.style['overflow-y'] = 'auto';
    this.setState({ openNavigation: false });
  }

  render() {
    const { nav, descriptors } = this.props;
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const transitionRef = createRef(null);

    return (
      <HeaderHolder>
        <header
          id="mainHeader"
          className={'header ' + (this.props.headerGhost ? 'header--ghost ' : ' ') + (iOS ? 'ios' : '')}
        >
          <div className="header__container">
            <div className="header__overlay"></div>

            {descriptors && descriptors[this.levelDescriptorUrl] &&
            this.renderHeaderLogo(descriptors[this.levelDescriptorUrl])
            }

            <div className="header__navigation">
              <nav className="navigation">
                <ul className="navigation__list">
                  {descriptors && descriptors[this.levelDescriptorUrl] &&
                    this.renderHeaderHomeLink(descriptors[this.levelDescriptorUrl])
                  }
                  {
                    nav
                    && nav.entries['/']
                    && this.renderNavItems()
                  }
                </ul>
              </nav>
            </div>
            <div className="header__search">
              <div className="mobile__navigation">
                <FontAwesomeIcon className="bars__icon" icon={faBars} onClick={this.onShowNavigationMenu} />
              </div>
              <div>
                <HeaderSearch />
              </div>
            </div>
          </div>
        </header>
        {
          <CSSTransition
            in={this.state.openNavigation}
            timeout={300}
            nodeRef={transitionRef}
            unmountOnExit
            classNames="navigation__transition"
          >
            <NavbarMobileContainer
              ref={transitionRef}
            >
              <NavbarMenu>
                <div className="navigation__head">
                  <h2>Navigation</h2>
                  <div className="navigation__closeButton" onClick={this.onHideNavigationMenu}>
                    <FontAwesomeIcon icon={faWindowClose} />
                  </div>
                </div>
                <div className="menu__navigation">
                  <nav className="navigation">
                    <ul className="navigation__list" onClick={this.onHideNavigationMenu}>
                      { descriptors && descriptors[this.levelDescriptorUrl] &&
                        this.renderHeaderHomeLink(descriptors[this.levelDescriptorUrl])
                      }
                      {
                        nav && nav.entries['/'] && this.renderNavItems()
                      }
                      {
                        this.renderSearchLink()
                      }
                    </ul>
                  </nav>
                </div>
              </NavbarMenu>
            </NavbarMobileContainer>
          </CSSTransition>
        }
      </HeaderHolder>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getDescriptor: url => dispatch(getDescriptor(url))
});

const mapStateToProps = store => ({
  nav: store.craftercms.navigation,
  descriptors: store.craftercms.descriptors.entries,
  headerGhost: store.header.headerGhost
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
